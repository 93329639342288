<template>
  <DateInternal
    v-bind="dateProps"
    @input="$emit('input', dateToValue($event))"
  ></DateInternal>
</template>

<script>
import { toDate } from '../../helpers';
import DateInternal from './DateInternal.vue';

export default {
  name: 'UiInputDate',
  components: { DateInternal },

  props: {
    value: {
      type: [Date, String, Number],
      required: false,
      default: null,
    },

    /**
     * Especifica en que formato se emitirá el resultado
     */
    format: {
      type: String,
      required: false,
      default: 'object',
      validator: (val) => ['object', 'timestamp', 'day'].includes(val),
    },

    min: {
      type: [Date, String, Number],
      required: false,
      default: null,
    },

    max: {
      type: [Date, String, Number],
      required: false,
      default: null,
    },

    /**
     * Array con atributos para formatear la celda del dia
     * para fechas especificas
     *
     * [
     *  {
     *    "date": "1628485200",
     *    "className": "day-evaluacion"
     *  }
     * ]
     */
    daysOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    dateProps() {
      let daysOptions = this.daysOptions
        .filter((dayOpt) => !!dayOpt?.date)
        .map((dayOpt) => ({
          ...dayOpt,
          date: toDate(dayOpt.date),
        }));

      return Object.assign({}, this.$attrs, {
        min: toDate(this.min),
        max: toDate(this.max),
        value: toDate(this.value),
        daysOptions,
      });
    },
  },

  watch: {
    format: {
      immediate: false,
      handler() {
        this.$emit('input', this.dateToValue(this.dateProps.value));
      },
    },
  },

  methods: {
    dateToValue(objDate) {
      if (!objDate) {
        return null;
      }

      switch (this.format) {
        case 'timestamp': {
          return Math.floor(objDate.getTime() / 1000);
        }

        case 'day': {
          let day = objDate.getDate().toString().padStart(2, '0');
          let month = (objDate.getMonth() + 1).toString().padStart(2, '0');
          return day + month + objDate.getFullYear();
        }

        case 'object':
        default: {
          return objDate;
        }
      }
    },
  },
};
</script>