<template>
  <div
    class="ui-input-date ui-inset ui-noselect"
    :class="{'--mobile':isMobile, '--empty':!date, '--disabled':disabled}"
  >
    <template v-if="isMobile">
      <input
        class="main-input"
        type="text"
      />

      <ui-item
        class="ui-input-date-face --nowrap"
        :text="displayedDate"
        @click="onClickFace"
      >
        <template #right>
          <ui-icon
            class="icon-right ui-padded"
            value="g:today"
          ></ui-icon>
        </template>
      </ui-item>
    </template>

    <ui-popover
      class="date-popover"
      v-else
      :open.sync="isOpen"
      autoload
    >
      <template #trigger>
        <ui-item
          class="ui-input-date-face"
          :text="displayedDate"
        >
          <template #right>
            <ui-icon
              class="icon-right ui-padded"
              value="g:today"
            ></ui-icon>
          </template>
        </ui-item>
      </template>

      <template #contents>
        <div class="ui-input-date-popup">
          <input
            class="main-input"
            type="text"
          />

          <footer>
            <button
              v-if="showTodayButton"
              type="button"
              class="ui-button"
              @click="setToday()"
            >{{ $t("DatePicker.Today") }}</button>

            <button
              type="button"
              class="ui-button --main"
              @click="save()"
              v-if="time"
            >{{ $t("DatePicker.OK") }}</button>

            <button
              type="button"
              class="ui-button --cancel"
              @click="clear()"
            >{{ $t("DatePicker.Clear") }}</button>
          </footer>
        </div>
      </template>
    </ui-popover>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'flatpickr';

import useI18n from '@/modules/i18n/mixins/useI18n.js';

import { UiIcon, UiItem, UiPopover } from '@/modules/ui/components';

export default {
  name: 'date-picker',
  mixins: [useI18n],

  components: {
    UiIcon,
    UiItem,
    UiPopover,
  },

  props: {
    value: {
      type: Date,
      required: false,
      default: null,
    },

    time: {
      type: String,
      required: false,
      default: null,
      validator: (value) => [null, '12', '24'].includes(value),
    },

    placeholder: {
      type: String,
      required: false,
      default: null,
    },

    showTodayButton: {
      type: Boolean,
      required: false,
      default: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableBefore: {
      type: [Date, String, Number],
      required: false,
      default: null,
    },

    min: {
      type: Date,
      required: false,
      default: null,
    },

    max: {
      type: Date,
      required: false,
      default: null,
    },

    /**
     * Array con atributos para formatear la celda del dia
     * para fechas especificas
     *
     * [
     *  {
     *    "date": "1628485200",
     *    "className": "day-evaluacion"
     *  }
     * ]
     */
    daysOptions: {
      type: Array,
      required: false,
      default: () => [],
    },

    /**
     * Función onDayCreate a pasar directamente a flatpicker
     */
    onDayCreate: {
      type: Function,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      date: null, // valor representado como un objeto Date
      instance: null,
      config: {},
      isOpen: false,
    };
  },

  computed: {
    isMobile() {
      let check = false;
      // eslint-disable-next-line no-useless-escape
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },

    displayedDate() {
      if (!this.date) {
        return this.placeholder;
      }

      let retval = this.$date(this.date, 'day');
      if (this.time) {
        retval += ' ' + this.$date(this.date, 'time');
      }
      return retval;
    },
  },

  watch: {
    value(newValue) {
      this.setDate(newValue);
    },

    time(newValue) {
      this.initializeInstance();
    },

    disableBefore(newValue) {
      this.initializeInstance();
    },
  },

  mounted() {
    this.date = this.value ? new Date(this.value) : null;
    this.initializeInstance();
  },

  methods: {
    onClickFace() {
      this.instance.open();
    },

    initializeInstance() {
      let flatpickrOptions = {
        enableTime: this.time != null,
        time_24hr: this.time == '24',
        inline: !this.isMobile,
        onChange: this.onInstanceChange,
        minDate: this.min,
        maxDate: this.max,

        onDayCreate: (dObj, dStr, fp, dayElem) => {
          let customDayOptions = this.daysOptions.find(
            (dayOpt) =>
              dayOpt.date.toDateString() == dayElem.dateObj.toDateString()
          );
          if (customDayOptions?.className) {
            dayElem.className += ' ' + customDayOptions.className;
          }

          if (this.onDayCreate) {
            this.onDayCreate(dObj, dStr, fp, dayElem);
          }
        },
      };

      if (this.disableBefore) {
        let dateBefore =
          typeof this.disableBefore == 'object'
            ? this.disableBefore
            : new Date(this.disableBefore * 1000);

        dateBefore.setHours(0, 0, 0, 0);

        flatpickrOptions.disable = [(date) => date < dateBefore];
      }

      let fpElement = this.$el.querySelector('.main-input');
      if (fpElement) {
        this.instance = Flatpickr(fpElement, flatpickrOptions);
        this.instance.setDate(this.date);
      } else {
        // El elemento no esta renderizado aun (!?).   Esperar un poco
        setTimeout(() => {
          let fpElement = this.$el.querySelector('.main-input');
          this.instance = Flatpickr(fpElement, flatpickrOptions);
          this.instance.setDate(this.date);
        }, 250);
      }
    },

    setDate(newValue) {
      if (newValue == this.date) {
        return;
      }

      if (
        newValue != null &&
        this.date != null &&
        newValue.getTime() == this.date.getTime()
      ) {
        return;
      }

      if (!newValue) {
        this.date = null;
        this.instance.clear();
        return;
      }

      this.date = new Date(newValue);
      this.instance.setDate(newValue);
    },

    close() {
      this.isOpen = false;
    },

    save() {
      let value = this.instance.selectedDates.length
        ? this.instance.selectedDates[0]
        : null;

      if (this.date == value) {
        this.close();
        return;
      }

      this.date = value ? new Date(value) : null;
      this.$emit('input', this.date);
      this.close();
    },

    onInstanceChange() {
      // si no hay selector de tiempo, guardar al seleccionar un dia
      if (this.isMobile || !this.time) {
        this.save();
      }
    },

    setToday() {
      this.setDate(new Date());
      if (!this.time) {
        this.save();
      }
    },

    clear() {
      this.setDate(null);
      this.$emit('input', null);
      this.close();
    },
  },

  i18n: {
    en: {
      'DatePicker.OK': 'OK',
      'DatePicker.Today': 'Today',
      'DatePicker.Clear': 'Clear',
    },

    de: {
      'DatePicker.Today': 'Heute',
    },

    es: {
      'DatePicker.OK': 'OK',
      'DatePicker.Today': 'Hoy',
      'DatePicker.Clear': 'Limpiar',
    },

    fr: {
      'DatePicker.Today': "Aujourd'hui",
    },

    it: {
      'DatePicker.Today': 'Oggi',
    },
  },
};
</script>

<style lang="scss">
.ui-input-date {
  display: inline-block;
  position: relative;

  .ui-input-date-face {
    .icon-right {
      opacity: 0.7;
    }
  }

  // Hide the main input tag
  .main-input {
    z-index: -10;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    background: transparent;

    // visibility: hidden; // ESTO IMPIDE instance.open() en IOS !!!
  }

  .flatpickr-calendar {
    box-shadow: none;
  }

  .ui-input-date-popup {
    footer {
      text-align: center;
      padding: var(--ui-padding-vertical) 0;
      background-color: #fcfcfc;
      button {
        margin: 0 1em;
      }
    }
  }

  // Placeholder when empty (!!! is styling .item-body with is a ui-item internal)
  &.--empty {
    .ui-input-date-face .item-body {
      opacity: 0.5;
    }
  }

  &.--mobile {
    .ui-input-date-popup {
      display: block;
      visibility: hidden;
    }
  }

  &.--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .date-popover {
    .ui-popover-contents {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    }

    // .ui-popover-arrow {
    //   // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    //   filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 1));
    // }

    // .ui-popover-arrow::before {
    //   // filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.3));
    //   clip-path: polygon(0 0, 0 100%, 100% 0);
    // }
  }
}
</style>